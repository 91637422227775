
import Component from 'vue-class-component'
import { Emit, Mixins, Prop } from 'vue-property-decorator'
import ValidationMixin from '@/mixins/ValidationMixin.vue'
import FormMixin from '@/mixins/FormMixin.vue'
import Auth from '@/apis/AuthApi'

@Component({
  name: 'LoginForm'
})

export default class LoginForm extends Mixins(ValidationMixin, FormMixin) {
  @Prop(Boolean) enteringPassword!: boolean
  @Prop(Array) whiteListEmails!: string[]

  @Emit()
  onEmailEnter(email) {
    return email
  }

  @Emit()
  onPasswordEnter(password) {
    return password
  }

  form = {
    email: '',
    password: ''
  }

  isShowModal = false

  isValidModalForm = false
  loadingRestore = false

  emailRestorePassword = ''

  showPassword = false

  get emailRules() {
    const rules = [
      // @ts-ignore
      this.RULES.required
      // this.RULES.email
    ]

    // if (this.$store.getters['auth/userRole'] === 'manager-lc') {
    //   rules.push((e) => this.RULES.lkEmail(e, this.whiteListEmails))
    // }

    return rules
  }

  sendRestoreBtnClicked() {
    if (this.form.email) {
      Auth.sendClickRestoreBtn(this.form.email)
    }
  }

  restorePassword() {
    this.loadingRestore = true
    // @ts-ignore
    Auth.restorePassword(this.emailRestorePassword)
      .then(() => {
        // @ts-ignore
        this.showSuccessSystemNotification('На Вашу почту отправлено письмо для восстановления пароля')
      })
      // .catch(() => {
      //   this.showErrorSystemNotification('Что-то пошло не так! Попробуйте позже!')
      // })
      .finally(() => {
        this.loadingRestore = false
        this.isShowModal = false
      })
  }
}

const permittedFileMimes = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
]

const permittedFileMimesForAdmin = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
]

// const permittedFileSize = 10485760

export const rules = {
  required: (v: any) => !!v || 'Заполните поле',
  email: (v: any) => !v || /\S+@\S+\.\S+/.test(v) || 'Вы указали некорректный e-mail',
  lkEmail: (v: any, whiteList: any) => !v ||
    whiteList.some((y: any) => new RegExp(`@.*?(${y})`).test(v)) ||
    'Такой лизинговой компании у нас пока нет. Напишите нам на почту support@poiskpl.ru и мы вас добавим',
  repeatPassword: (v: any, password: any) => !v || v === password || 'Пароли не совпадают',
  phone: (phone: any) => !phone || phone.length === 11 || 'Введите номер телефона полностью',
  cancelTextR: (v: any) => v.length <= 25 || 'Максимальная длина 25 символом',
  fileMime: (v: any) => !v || permittedFileMimes.indexOf(v.type) !== -1 || 'Загрузите файл нужного расширения: doc, docx, pdf, xls, xlsx',
  fileMimeForAdmin: (v: any) => !v || permittedFileMimesForAdmin.indexOf(v.type) !== -1 || 'Загрузите файл нужного расширения: xlsx'
}

export const acceptedFileMimes = permittedFileMimes


import Vue from 'vue'
import { Ref, Component, PropSync } from 'vue-property-decorator'

export interface VForm {
  resetValidation(): void;
  valid: boolean;
  form: any;
}

@Component

export default class FormMixin extends Vue {
  @Ref('form') readonly formRef!: VForm
  @PropSync('isValid', { type: Boolean, default: false }) valid!: boolean

  // valid = false

  public resetValidation() {
    // @ts-ignore
    this.formRef?.resetValidation() && this.formRef.resetValidation()
  }
}


import Vue from 'vue'
import Component from 'vue-class-component'
import { mapActions } from 'vuex'
import Auth from '@/apis/AuthApi'

import LoginForm from '@/views/Auth/components/LoginForm.vue'
import { Ref } from 'vue-property-decorator'
import { VForm } from '@/mixins/FormMixin.vue'
import { UserRoles } from '@/typings/UserTypings'
// import { system } from '@/utils/system'

@Component({
  name: 'Login',
  components: {
    LoginForm
  },
  methods: {
    ...mapActions({
      signIn: 'auth/signIn'
    })
  }
})

export default class Login extends Vue {
  @Ref() readonly loginForm!: VForm

  isFormValid = false
  loading = false
  enteringPassword = false
  isCheckEmail = false
  whiteListEmails = []

  advantages = []

  validationErrors = []

  errors = ''

  showErrors = false

  get isDisabledBtn() {
    return this.loading || this.isCheckEmail || !this.isFormValid
  }

  get userRole() {
    return this.$store.getters['auth/userRole']
  }

  get userRoleName() {
    switch (this.userRole) {
      case 'manager-lc':
        return 'менеджера лизинговой компании'
      case 'manager-ds':
        return 'менеджера дилерского центра'
      case 'superviser':
        return 'супервайзера лизинговой компании'
      case 'admin':
        return 'администратора'
    }
    // return this.userRole === 'manager-lc'
    //   ? 'менеджера лизинговой компании'
    //   : 'менеджера дилерского центра'
  }

  created() {
    // @ts-ignore
    this.$setLoading(true)
    const userRole = this.$route.params.userRole ?? UserRoles[2]
    this.$store.commit('auth/SET_USER_ROLE', userRole)

    Auth.details(UserRoles[userRole])
      .then(({ data }) => {
        this.whiteListEmails = data.whiteListEmails
        this.advantages = data.roleDetails
      })
      .finally(() => {
        // @ts-ignore
        this.$setLoading(false)
      })
  }

  login() {
    // @ts-ignore
    this.signIn(this.loginForm.form)
      .then(() => {
        this.$router.push({ name: 'Home' })
      })
      .catch((errors) => {
        this.errors = errors.response.data
        this.validationErrors = errors.response.data.errors
      })
  }

  checkUser() {
    if (this.isFormValid) {
      this.loading = true
      const roleId = UserRoles[this.$store.getters['auth/userRole']]
      Auth.checkUserExist(this.loginForm.form.email, roleId)
        .then(response => {
          if (response.data.userExist) {
            this.enteringPassword = true
          } else {
            this.isCheckEmail = true
            // @ts-ignore
            this.showSuccessSystemNotification('На указанный почтовый ящик было отправлено письмо для регистрации')
          }
        })
        .catch((errors) => {
          this.errors = errors.response.data
          this.validationErrors = errors.response.data.errors
        })
        .finally(() => {
          this.loading = false
        })
    } else {
      // @ts-ignore
      this.showErrorSystemNotification('Введите свой email')
    }
  }
}


import Vue from 'vue'
import Component from 'vue-class-component'
import { rules } from '@/validation/rules'
import { Prop, PropSync } from 'vue-property-decorator'
import _ from 'lodash'

@Component

export default class ValidationMixin extends Vue {
  @PropSync('errors') validationErrors!: any
  readonly RULES = rules

  fillErrors(errors) {
    this.validationErrors = errors.response.data
  }

  clearError(name) {
    this.validationErrors = _.omit(this.validationErrors, name)
  }
}
